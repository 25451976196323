import * as React from 'react';

import { Route, isSignedIn, ProductImport, Import } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/products/product-import',
        main: () => import('./ProductImportOverview'),
        isAuthorized: isSignedIn,
        preloadData: ProductImport.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/products/product-import/:importId',
        main: () => import('./ProductImportDetail'),
        isAuthorized: isSignedIn,
        preloadData: Import.detailsState.actions.load,
        preloading: () => <FullPage bodyType="tabs-and-boxes" />,
    },
    {
        path: '/products/product-import/new',
        main: () => import('./ProductImportPreview'),
        isAuthorized: isSignedIn,
        preloading: () => (
            <FullPage
                hideMainActions={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
