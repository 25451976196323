import {
    Ownership,
    Pagination,
    Sorting,
    WEEK_DAYS,
    OpeningHours,
    BranchesPage,
    BranchDetails,
    PublicBranchDetails,
    CreateBranchPayload,
    UpdateBranchPayload,
    UpdateBranchOpeningHoursPayload,
    UpdateBranchRobotOutputDestinationsPayload,
    BranchRobotStockLocations,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class BranchEndpoint extends Endpoint {
    public async GetBranches(
        ownership: Ownership,
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        companyId?: string,
        filters?: {
            branchName?: string;
            address?: string;
            zipCode?: string;
            city?: string;
            includeInactive?: string;
        },
    ): Promise<BranchesPage> {
        let url = '/branch/overview';
        if (ownership.type === 'company') {
            url = `/company/${ownership.ownerId}/branches`;
        }

        const filtersData: typeof filters & {
            companyId?: string;
        } = {
            ...filters,
        };

        if (companyId) {
            filtersData.companyId = companyId;
        }

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
                filters: filtersData,
            },
        });

        this.guardResponseSchema('#/definitions/BranchesPage', response);

        return response;
    }

    public async GetBranchDetails(branchId: string): Promise<BranchDetails> {
        let response = await this.client.get({
            url: `/branch/details/${branchId}`,
        });

        if (Array.isArray(response) || !response.openingHours || Array.isArray(response.openingHours)) {
            response = {
                ...response,
                openingHours: WEEK_DAYS.reduce((openingHours: Partial<OpeningHours>, day) => {
                    openingHours[day] = [];
                    return openingHours;
                }, {}) as OpeningHours,
            };
        }

        this.guardResponseSchema('#/definitions/BranchDetails', response);

        return response;
    }
    public async GetPublicBranchDetails(branchId: string): Promise<PublicBranchDetails> {
        const response = await this.client.get({
            url: `/branch/public-details/${branchId}`,
        });

        return response;
    }

    public async CreateBranch(payload: CreateBranchPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateBranch',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\Branch\\CreateBranch\\CreateBranch',
                payload,
            },
        });
    }

    public async UpdateBranch(payload: UpdateBranchPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateBranch',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\Branch\\UpdateBranch\\UpdateBranch',
                payload,
            },
        });
    }

    public async RemoveBranch(branchId: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveBranch',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\Branch\\RemoveBranch\\RemoveBranch',
                payload: {
                    branchId,
                },
            },
        });
    }

    public GetBranchesExportURL(exportType: 'csv' | 'xlsx'): string {
        return this.client.buildApiURL(`/branch/export/${exportType}`, true);
    }

    public async UpdateBranchOpeningHours(payload: UpdateBranchOpeningHoursPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateBranchOpeningHours',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\Branch\\UpdateBranchOpeningHours\\UpdateBranchOpeningHours',
                payload,
            },
        });
    }

    public async UpdateBranchRobotOutputDestinations(payload: UpdateBranchRobotOutputDestinationsPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateBranchRobotOutputDestinations',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Company\\Branch\\UpdateBranchRobotOutputDestinations\\UpdateBranchRobotOutputDestinations',
                payload,
            },
        });
    }

    public async GetBranchRobotStockLocations(branchId: string): Promise<BranchRobotStockLocations> {
        const response = await this.client.get({
            url: `/branch/${branchId}/stock-locations`,
        });

        this.guardResponseSchema('#/definitions/BranchRobotStockLocations', response);

        return response;
    }
}
