import * as React from 'react';
import { useSelector } from 'react-redux';

import { config, configUtils } from 'config';
import {
    useGettext,
    maybeSelectLoggedInUser,
    selectIsLoggedInAsCompanyManager,
    selectIsLoggedInAsBranchManager,
    selectIsLoggedInAsPlatformAdministrator,
    selectIsLoggedInAsSupportAgent,
    selectIsLoggedInAsReseller,
    maybeSelectLoggedInUserOwnershipIds,
    Webshop,
    Device,
} from 'data-store';
import { SidebarNavigation as SidebarNavigationComponent, SidebarNavigationMenuItem } from 'sidebar-navigation';
import { Icon } from 'ui-components';

export default function SidebarNavigation(props: { id?: string }) {
    const { gettext } = useGettext();
    const loggedInUser = useSelector(maybeSelectLoggedInUser);
    const ownershipIds = useSelector(maybeSelectLoggedInUserOwnershipIds);
    const isPlatformAdministrator = useSelector(selectIsLoggedInAsPlatformAdministrator);
    const isSupportAgent = useSelector(selectIsLoggedInAsSupportAgent);
    const isReseller = useSelector(selectIsLoggedInAsReseller);
    const isCompanyManager = useSelector(selectIsLoggedInAsCompanyManager);
    const isBranchManager = useSelector(selectIsLoggedInAsBranchManager);
    const hasAtLeastOneWebshop = useSelector(Webshop.globalSelectionState.selectGlobalSelectedWebshopId);
    const hasNoWebshops = useSelector(Webshop.globalSelectionState.selectNoWebshopsToSelectFrom);
    const hasAtLeastOneVendingMachine = useSelector(Device.globalSelectionState.selectGlobalSelectedDeviceId);
    const hasVendingMachineDevices = useSelector(Device.globalSelectionState.selectHasVendingMachineDevices);
    const hasVisionDevices = useSelector(Device.globalSelectionState.selectHasVisionDevices);
    const hasCollectDevices = useSelector(Device.globalSelectionState.selectHasCollectDevices);
    let enableBacksideStocking = config.featureFlags.enableBacksideStocking;
    if (enableBacksideStocking && config.featureFlags.enableBacksideStockingOnlyForCompany) {
        enableBacksideStocking = !!(
            ownershipIds.companyId && ownershipIds.companyId === config.featureFlags.enableBacksideStockingOnlyForCompany
        );
    }
    const isContentProviderCompany = loggedInUser?.companyType === 'contentProvider';
    const showWebshopsOverview = !hasNoWebshops || isPlatformAdministrator;
    const enableNightHatchV2 = configUtils.getNightHatchV2IsEnabled(ownershipIds.companyId || '');
    const enableIdentifiedCustomerAuthentication =
        config.featureFlags.enableIdentifiedCustomersForCompanies &&
        ownershipIds.companyId &&
        config.featureFlags.enableIdentifiedCustomersForCompanies.includes(ownershipIds.companyId);

    const landingMenuItem = {
        key: 'landing',
        label: gettext('Home'),
        icon: (
            <Icon
                type="nav_store"
                iconSize="l"
            />
        ),
        to: '/',
        className: 'hidden-md',
    };

    const dashboardMenuItem = {
        key: 'dashboard',
        label: gettext('Dashboard'),
        icon: (
            <Icon
                type="nav_dashboard"
                iconSize="l"
            />
        ),
        to: '/dashboard',
        children: [
            {
                key: 'dashboard--analytics',
                label: gettext('Analytics'),
                icon: <Icon type="nav_analytics" />,
                description: gettext('Visualise your sales results.'),
                to: '/dashboard/analytics',
            },
            {
                key: 'dashboard--plausible-analytics',
                label: gettext('Plausible Analytics'),
                icon: <Icon type="nav_plausible_analytics" />,
                description: gettext('Visualise sales channel analytics'),
                to: '/dashboard/plausible-analytics',
            },
            {
                key: 'dashboard--all-notifications',
                label: gettext('All notifications'),
                icon: <Icon type="nav_notifications" />,
                description: gettext('Browse and manage all notifications.'),
                to: '/dashboard/all-notifications',
            },
            {
                key: 'dashboard--lochting-news',
                label: gettext('Lochting News'),
                icon: <Icon type="nav_lochting_news" />,
                description: gettext('Browse and read all Lochting news.'),
                to: '/dashboard/lochting-news',
            },
            {
                key: 'dashboard--audit-log',
                label: gettext('Audit log'),
                icon: <Icon type="nav_audit_log" />,
                description: gettext('Browse audit log.'),
                to: '/dashboard/audit-log',
            },
        ],
    };

    if (isPlatformAdministrator || isSupportAgent || isReseller) {
        dashboardMenuItem.children.push({
            key: 'dashboard--all-platform-notifications',
            label: gettext('All platform notifications'),
            icon: <Icon type="nav_all_notifications" />,
            description: gettext('Browse and manage all platform notifications.'),
            to: '/dashboard/all-platform-notifications',
        });
    }

    const localisationsMenuItem = {
        key: 'localisation',
        label: gettext('Localisation'),
        icon: (
            <Icon
                type="nav_localisation"
                iconSize="l"
            />
        ),
        to: '/localisation',
        children: [
            isPlatformAdministrator || isSupportAgent
                ? {
                      key: 'localisation--countries-and-languages',
                      label: gettext('Countries and languages'),
                      icon: <Icon type="nav_earth" />,
                      description: gettext('Manage countries and languages.'),
                      to: '/localisation/countries-and-languages',
                  }
                : false,
            isPlatformAdministrator || isSupportAgent
                ? {
                      key: 'localisation--sources-and-settings',
                      label: gettext('Sources and settings'),
                      icon: <Icon type="nav_sources" />,
                      description: gettext('Manage product sources.'),
                      to: '/localisation/sources-and-settings',
                  }
                : false,
            {
                key: 'localisation--translation-management',
                label: gettext('Translation management'),
                icon: <Icon type="nav_bubble" />,
                description: gettext('Manage translations.'),
                to: '/localisation/translation-management/overview',
            },
            isPlatformAdministrator || isSupportAgent || isReseller
                ? {
                      key: 'localisation--quality-labels',
                      label: gettext('Quality labels'),
                      icon: <Icon type="nav_prize" />,
                      description: gettext('Manage quality labels.'),
                      to: '/localisation/quality-labels',
                  }
                : false,
            isPlatformAdministrator
                ? {
                      key: 'localisation--search-synonyms',
                      label: gettext('Search synonyms'),
                      icon: <Icon type="nav_search_synonyms" />,
                      description: gettext('Manage search synonyms.'),
                      to: '/localisation/search-synonyms',
                  }
                : false,
            isPlatformAdministrator
                ? {
                      key: 'localisation--search-actions-export',
                      label: gettext('Search Actions export'),
                      icon: <Icon type="nav_search_action_exports" />,
                      description: gettext('Export search actions.'),
                      to: '/localisation/search-actions/export',
                  }
                : false,
            isPlatformAdministrator
                ? {
                      key: 'localisation--cms-items',
                      label: gettext('CMS'),
                      icon: <Icon type="nav_cms" />,
                      description: gettext('CMS pages, Steamer.'),
                      to: '/cms/items',
                  }
                : false,
            isPlatformAdministrator
                ? {
                      key: 'localisation--cms-tags',
                      label: gettext('CMS Tags'),
                      icon: <Icon type="nav_cms_tags" />,
                      description: gettext('CMS Tags.'),
                      to: '/cms/tags',
                  }
                : false,
        ].filter(Boolean),
    };

    const customersMenuItem = {
        key: 'customers',
        label: gettext('Customers'),
        icon: (
            <Icon
                type="nav_customers"
                iconSize="l"
            />
        ),
        to: '/customers',
        children: [
            {
                key: 'customers--companies',
                label: gettext('Companies'),
                icon: <Icon type="nav_apartment" />,
                description: gettext('Browse and manage companies.'),
                to: '/customers/companies',
            },
            {
                key: 'customers--branches',
                label: gettext('Branches'),
                icon: <Icon type="nav_branches" />,
                description: gettext('Browse and manage branches.'),
                to: '/customers/branches',
            },
            {
                key: 'customers--users',
                label: gettext('Users'),
                icon: <Icon type="nav_users" />,
                description: gettext('Browse and manage users.'),
                to: '/customers/users',
            },
            {
                key: 'customers--devices',
                label: gettext('Devices'),
                icon: <Icon type="nav_screen_devices" />,
                description: gettext('Browse and manage devices.'),
                to: '/customers/devices',
            },
            isPlatformAdministrator || isSupportAgent || isReseller
                ? {
                      key: 'customers--sharing-groups',
                      label: gettext('Sharing groups'),
                      icon: <Icon type="nav_sharing_groups" />,
                      description: gettext('Browse and manage sharing groups.'),
                      to: '/customers/sharing-groups',
                  }
                : false,
            isPlatformAdministrator || isSupportAgent
                ? {
                      key: 'customers--resellers',
                      label: gettext('Resellers'),
                      icon: <Icon type="nav_resellers" />,
                      description: gettext('Browse and manage resellers.'),
                      to: '/customers/resellers',
                  }
                : false,
            isPlatformAdministrator
                ? {
                      key: 'customers--api-keys',
                      label: gettext('API keys'),
                      icon: <Icon type="nav_puzzle" />,
                      description: gettext('Browse and manage API keys.'),
                      to: '/customers/api-keys',
                  }
                : false,
        ].filter(Boolean),
    };

    const productsMenuItem = {
        key: 'products',
        label: gettext('Products'),
        icon: (
            <Icon
                type="nav_products"
                iconSize="l"
            />
        ),
        to: '/products',
        children: [
            {
                key: 'products--available-products',
                label: gettext('Available products'),
                icon: <Icon type="nav_public_products" />,
                description: gettext('Browse public products database.'),
                to: '/products/available-products',
            },
            isCompanyManager || isBranchManager || isPlatformAdministrator || isSupportAgent || isReseller
                ? {
                      key: 'products--product-feedback',
                      label: gettext('Product feedback'),
                      icon: <Icon type="nav_product_feedback" />,
                      description: gettext('Submit and manage public products feedback.'),
                      to: '/products/product-feedback',
                  }
                : false,
            isCompanyManager || isBranchManager
                ? {
                      key: 'products--custom-products',
                      label: gettext('Custom products'),
                      icon: <Icon type="nav_custom_product" />,
                      description: gettext('Browse and manage custom products.'),
                      to: '/products/custom-products',
                  }
                : false,
            {
                key: 'products--product-import',
                label: gettext('Product import'),
                icon: <Icon type="nav_imports" />,
                description: gettext('Import products from CSV files.'),
                to: '/products/product-import',
            },
            {
                key: 'products--product-export',
                label: gettext('Product export'),
                icon: <Icon type="nav_exports" />,
                description: gettext('Export products.'),
                to: '/products/product-export',
            },
            isBranchManager && (hasVisionDevices || hasAtLeastOneVendingMachine)
                ? {
                      key: 'products--in-store-selection',
                      label: gettext('In store selection'),
                      icon: <Icon type="nav_presentations" />,
                      description: gettext('Browse and manage in store products selection.'),
                      to: '/products/selection/inStoreProductSelection',
                  }
                : false,
            isBranchManager && hasAtLeastOneWebshop
                ? {
                      key: 'products--webshop-selection',
                      label: gettext('Webshop selection'),
                      icon: <Icon type="nav_webshop_selection" />,
                      description: gettext('Browse and manage webshop products selection.'),
                      to: '/products/selection/webShopProductSelection',
                  }
                : false,
            isBranchManager && hasAtLeastOneVendingMachine
                ? {
                      key: 'products--robot-selection',
                      label: gettext('Robot selection'),
                      icon: <Icon type="nav_vending" />,
                      description: gettext('Browse and manage vending machine products selection.'),
                      to: '/products/selection/robotProductSelection',
                  }
                : false,
            isCompanyManager
                ? {
                      key: 'products--in-store-selection',
                      label: gettext('In store custom prices'),
                      icon: <Icon type="nav_presentation" />,
                      description: gettext('Browse and manage in store products custom prices.'),
                      to: '/products/selection/inStoreProductSelection',
                  }
                : false,
            isCompanyManager
                ? {
                      key: 'products--webshop-selection',
                      label: gettext('Webshop custom prices'),
                      icon: <Icon type="nav_webshop" />,
                      description: gettext('Browse and manage webshop products custom prices.'),
                      to: '/products/selection/webShopProductSelection',
                  }
                : false,
            isCompanyManager
                ? {
                      key: 'products--robot-selection',
                      label: gettext('Robot custom prices'),
                      icon: <Icon type="nav_matic" />,
                      description: gettext('Browse and manage vending machine products custom prices.'),
                      to: '/products/selection/robotProductSelection',
                  }
                : false,
        ].filter(Boolean) as SidebarNavigationMenuItem['children'],
    };

    const mediaMenuItem = {
        key: 'media',
        label: gettext('Media'),
        icon: (
            <Icon
                type="nav_media"
                iconSize="l"
            />
        ),
        to: '/media',
    };

    const presentationsMenuItem = {
        key: 'presentations',
        label: gettext('Presentations'),
        icon: (
            <Icon
                type="nav_presentations"
                iconSize="l"
            />
        ),
        to: '/presentations',
        children: [
            {
                key: 'presentations--overview',
                label: gettext('Visions'),
                icon: <Icon type="nav_presentations" />,
                description: gettext('See Vision offer.'),
                to: '/vision-promo',
            },
        ],
    };

    const hasAccessToPresentationsMenuItem =
        hasVisionDevices || hasVendingMachineDevices || isPlatformAdministrator || isSupportAgent || isReseller;
    if (isContentProviderCompany || hasAccessToPresentationsMenuItem) {
        presentationsMenuItem.children = [
            {
                key: 'presentations--product-walls',
                label: gettext('Product walls'),
                icon: <Icon type="nav_product_walls" />,
                description: gettext('Browse and manage slideshow product walls.'),
                to: '/presentations/product-walls',
            },
            {
                key: 'presentations--custom-pages',
                label: gettext('Custom pages'),
                icon: <Icon type="nav_custom_pages" />,
                description: gettext('Browse and manage slideshow custom pages.'),
                to: '/presentations/custom-pages',
            },
            {
                key: 'presentations--slideshows',
                label: gettext('Slideshows'),
                icon: <Icon type="nav_slideshows" />,
                description: gettext('Browse and manage slideshows.'),
                to: '/presentations/slideshows',
            },
        ];

        if (hasAccessToPresentationsMenuItem) {
            presentationsMenuItem.children.push({
                key: 'presentations--schedules',
                label: gettext('Schedules'),
                icon: <Icon type="nav_schedule" />,
                description: gettext('Browse and manage device schedules.'),
                to: '/presentations/schedules',
            });
        }
    }

    const integrationsMenuItem = {
        key: 'integrations',
        label: gettext('Integrations'),
        icon: (
            <Icon
                type="nav_integrations"
                iconSize="l"
            />
        ),
        to: '/integrations',
    };

    const pickupsMenuItem = hasAtLeastOneVendingMachine
        ? {
              key: 'pickups',
              label: gettext('Reservations'),
              icon: (
                  <Icon
                      type="nav_reservation"
                      iconSize="l"
                  />
              ),
              to: '/reservations',
          }
        : null;

    const companyMenuItem = {
        key: 'company',
        label: gettext('Company'),
        icon: (
            <Icon
                type="nav_apartment"
                iconSize="l"
            />
        ),
        to: '/company',
        children: [
            {
                key: 'company--branches',
                label: gettext('Branches'),
                icon: <Icon type="nav_home" />,
                description: gettext('Browse and manage branches.'),
                to: '/company/branches',
            },
            {
                key: 'company--users',
                label: gettext('Users'),
                icon: <Icon type="nav_users" />,
                description: gettext('Browse and manage users.'),
                to: '/company/users',
            },
            {
                key: 'company--devices',
                label: gettext('Devices'),
                icon: <Icon type="nav_tv" />,
                description: gettext('Browse and manage devices.'),
                to: '/company/devices',
            },
            {
                key: 'company--landing-page-configuration',
                label: gettext('Landing page configuration'),
                icon: <Icon type="nav_license" />,
                description: gettext('Edit landing page configuration.'),
                to: '/company/edit-landing-page-configuration',
            },
            {
                key: 'dashboard--tags',
                label: gettext('Tags management'),
                icon: <Icon type="nav_cms" />,
                description: gettext('Browse and manage all tags.'),
                to: '/branch/tags',
            },
        ],
    };

    const branchMenuItem = {
        key: 'branch',
        label: gettext('Branch'),
        icon: (
            <Icon
                type="nav_branch"
                iconSize="l"
            />
        ),
        to: '/branch',
        children: [
            {
                key: 'branch--devices',
                label: gettext('Devices'),
                icon: <Icon type="nav_screen_devices" />,
                description: gettext('Browse and manage devices.'),
                to: '/branch/devices',
            },
            {
                key: 'branch--opening-hours',
                label: gettext('Opening hours'),
                icon: <Icon type="nav_clock" />,
                description: gettext('Manage pharmacy opening hours.'),
                to: `/customers/branches/edit-opening-hours/${ownershipIds.branchId}`,
            },
            {
                key: 'dashboard--tags',
                label: gettext('Tags management'),
                icon: <Icon type="nav_cms" />,
                description: gettext('Browse and manage all tags.'),
                to: '/branch/tags',
            },
        ],
    };

    const webshopsMenuItem = {
        key: 'webshops',
        label: gettext('Webshops'),
        icon: (
            <Icon
                type="nav_shop"
                iconSize="l"
            />
        ),
        to: '/webshops',
        children: [
            {
                key: 'webshops--overview',
                label: gettext('Webshops'),
                icon: <Icon type="nav_shop" />,
                description: showWebshopsOverview ? gettext('Browse and manage all webshops.') : gettext('See webshops offer.'),
                to: showWebshopsOverview ? '/webshops/overview' : '/webshop-promo',
            },
        ],
    };

    if (hasAtLeastOneWebshop || isPlatformAdministrator) {
        webshopsMenuItem.children = [
            ...webshopsMenuItem.children,
            {
                key: 'webshops--content',
                label: gettext('Content'),
                icon: <Icon type="nav_content" />,
                description: gettext('Browse and manage all webshop content.'),
                to: '/webshops/content',
            },
        ];

        if (hasAtLeastOneWebshop) {
            webshopsMenuItem.children = [
                ...webshopsMenuItem.children,
                {
                    key: 'webshops--orders',
                    label: gettext('Orders'),
                    icon: <Icon type="nav_orders" />,
                    description: gettext('Browse and manage orders, prescriptions, shipments.'),
                    to: '/webshops/orders',
                },
            ];
        }

        if (isPlatformAdministrator) {
            webshopsMenuItem.children = [
                ...webshopsMenuItem.children,
                {
                    key: 'webshops--available-color-themes',
                    label: gettext('Available color themes'),
                    icon: <Icon type="nav_wand" />,
                    description: gettext('Configure available color themes.'),
                    to: '/webshops/available-color-themes',
                },
            ];
        }

        if (isBranchManager || isCompanyManager) {
            webshopsMenuItem.children = [
                ...webshopsMenuItem.children,
                {
                    key: 'webshops--layout-and-design',
                    label: gettext('Layout & design'),
                    icon: <Icon type="nav_layout" />,
                    description: gettext('Choose colors, typography, shapes and logos.'),
                    to: '/webshops/layout-and-design',
                },
                {
                    key: 'webshops--customers',
                    label: gettext('Customers'),
                    icon: <Icon type="nav_webshop_customers" />,
                    description: gettext('Browse all customers.'),
                    to: '/webshops/customers',
                },
                {
                    key: 'webshops--configuration',
                    label: gettext('Configuration'),
                    icon: <Icon type="nav_config" />,
                    description: gettext('Configure webshop settings.'),
                    to: '/webshops/configuration',
                },
            ];
        }
        if (isBranchManager) {
            webshopsMenuItem.children = [
                ...webshopsMenuItem.children,
                {
                    key: 'webshops--appointments',
                    label: gettext('Appointments'),
                    icon: <Icon type="nav_appointments" />,
                    description: gettext('Create and manage appointments.'),
                    to: '/webshops/appointments',
                },
            ];
        }
    }

    const vendingMachinesMenuItem = {
        key: 'vending-machines',
        label: gettext('Vending machines'),
        icon: (
            <Icon
                type="nav_vending_devices"
                iconSize="l"
            />
        ),
        to: '/vending-machines',
        children: [
            {
                key: 'vending-machines--overview',
                label: gettext('Vending machines'),
                icon: <Icon type="nav_vending_devices" />,
                description: hasVendingMachineDevices
                    ? gettext('Browse and manage all vending machines.')
                    : gettext('See vending machines offer.'),
                to: hasVendingMachineDevices ? '/vending-machines/overview' : '/matic-promo',
            },
        ],
    };

    if (hasAtLeastOneVendingMachine) {
        if (isBranchManager || isCompanyManager) {
            vendingMachinesMenuItem.children.push({
                key: 'vending-machines--night-hatch',
                label: gettext('Night hatch'),
                icon: <Icon type="nav_nighthatch" />,
                description: gettext('Control the Night Hatch.'),
                to: '/vending-machines/night-hatch',
            });

            if (enableNightHatchV2) {
                vendingMachinesMenuItem.children.push({
                    key: 'vending-machines--night-hatch-v2',
                    label: gettext('Night hatch V2'),
                    icon: <Icon type="nav_nighthatch" />,
                    description: gettext('Night Hatch new Design and UX.'),
                    to: '/vending-machines/night-hatch-v2',
                });
            }
        }

        vendingMachinesMenuItem.children.push({
            key: 'vending-machines--orders',
            label: gettext('Orders'),
            icon: <Icon type="nav_orders" />,
            description: gettext('Browse and manage orders and reservations.'),
            to: '/vending-machines/orders',
        });

        if (isBranchManager || isCompanyManager) {
            vendingMachinesMenuItem.children.push({
                key: 'vending-machines--configuration',
                label: gettext('Configuration'),
                icon: <Icon type="nav_config" />,
                description: gettext('Configure vending machine settings.'),
                to: '/vending-machines/configuration',
            });
        }

        if (isBranchManager) {
            vendingMachinesMenuItem.children.push({
                key: 'vending-machines--stock-replenish',
                label: gettext('Stock replenish'),
                icon: <Icon type="nav_stock_replenishment" />,
                description: gettext('Browse stock replenish'),
                to: '/vending-machines/stock-replenish',
            });

            if (enableIdentifiedCustomerAuthentication) {
                vendingMachinesMenuItem.children.push({
                    key: 'branch--customers',
                    label: gettext('Customers'),
                    icon: <Icon type="nav_webshop_customers" />,
                    description: gettext('Browse and manage customers.'),
                    to: '/branch/customers',
                });
            }
        }

        if (isBranchManager && hasCollectDevices && enableBacksideStocking) {
            vendingMachinesMenuItem.children.push({
                key: 'vending-machines--backside-stocking',
                label: gettext('Rear access inventory'),
                icon: <Icon type="collect_pick_locker" />,
                description: gettext('Rear access inventory for your MT.Collect.'),
                to: '/vending-machines/backside-stocking',
            });
        }
    }

    let menuItems: SidebarNavigationMenuItem[] = [];

    if (isPlatformAdministrator) {
        menuItems = [
            landingMenuItem,
            dashboardMenuItem,
            localisationsMenuItem,
            customersMenuItem,
            productsMenuItem,
            mediaMenuItem,
            webshopsMenuItem,
            vendingMachinesMenuItem,
            presentationsMenuItem,
            integrationsMenuItem,
        ].filter(Boolean) as SidebarNavigationMenuItem[];
    } else if (isSupportAgent) {
        menuItems = [
            landingMenuItem,
            dashboardMenuItem,
            customersMenuItem,
            productsMenuItem,
            mediaMenuItem,
            webshopsMenuItem,
            vendingMachinesMenuItem,
            presentationsMenuItem,
            integrationsMenuItem,
        ].filter(Boolean) as SidebarNavigationMenuItem[];
    } else if (isReseller) {
        menuItems = [
            landingMenuItem,
            dashboardMenuItem,
            localisationsMenuItem,
            customersMenuItem,
            productsMenuItem,
            mediaMenuItem,
            webshopsMenuItem,
            vendingMachinesMenuItem,
            presentationsMenuItem,
            integrationsMenuItem,
        ].filter(Boolean) as SidebarNavigationMenuItem[];
    } else if (isCompanyManager) {
        menuItems = [
            landingMenuItem,
            dashboardMenuItem,
            companyMenuItem,
            productsMenuItem,
            mediaMenuItem,
            webshopsMenuItem,
            vendingMachinesMenuItem,
            pickupsMenuItem,
            presentationsMenuItem,
            integrationsMenuItem,
        ].filter(Boolean) as SidebarNavigationMenuItem[];
    } else if (isBranchManager) {
        menuItems = [
            landingMenuItem,
            dashboardMenuItem,
            branchMenuItem,
            productsMenuItem,
            mediaMenuItem,
            webshopsMenuItem,
            vendingMachinesMenuItem,
            pickupsMenuItem,
            presentationsMenuItem,
            integrationsMenuItem,
        ].filter(Boolean) as SidebarNavigationMenuItem[];
    } else if (loggedInUser) {
        menuItems = [];
    }

    return (
        <SidebarNavigationComponent
            menuItems={menuItems}
            id={props.id}
        />
    );
}
