import { Domain } from 'api';

import { importApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type CustomersImportProps = keyof Domain.Import;

const defaultSorting: Domain.Sorting<CustomersImportProps> = {
    field: 'initializedOn',
    direction: 'descending',
};

const pageSize = 20;

export const selectors = makeSelectors<Domain.Import, CustomersImportProps>({
    getState: rootState => rootState.customersImport.overview,
});

export const reducerActions = makeReducerActions<Domain.Export, CustomersImportProps>({
    reducerPrefix: '@customersImport/overview',
});

export const actions = makeActions<Domain.Import, CustomersImportProps>({
    dataTableSaveKey: 'customersImportsOverview-v1',
    loadApi: options => importApi.GetCsvImports(options.pagination, options.sorting, { importType: 'identifiedCustomerImport' }),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/branch/customers/customers-import',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Import, CustomersImportProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
