import jwtDecode from 'jwt-decode';

import { Locale, Country, DeviceScreenResolution, CompanyType } from '@/Domain';

import JsonWebTokenException from './JsonWebTokenException';

interface ReservedClaims {
    iss: string;
    sub: string;
    aud: string;
    exp: number;
    nbf: number;
    iat: number;
}

interface BasePublicClaims {
    userId: string;
    username: string;
    canShareWithPlatform: boolean;
    canShareWithCountries: Country[] | null;
    canUseSharingGroups: boolean;
    branchName?: string;
    companyName?: string;
    branchTimezone?: string;
    companyType?: CompanyType;
}

export type PlatformAdministratorPublicClaims = BasePublicClaims & {
    role: 'platformAdministrator';
};

export type SupportAgentPublicClaims = BasePublicClaims & {
    role: 'supportAgent';
};

export type ResellerPublicClaims = BasePublicClaims & {
    role: 'reseller';
    resellerId: string;
    resellerCountry: Country;
};

export type CompanyManagerPublicClaims = BasePublicClaims & {
    role: 'companyAdministrator';
    companyId: string;
    companyCountry: Country;
    companyLocales: Locale[];
};

export type BranchManagerPublicClaims = BasePublicClaims & {
    role: 'branchAdministrator';
    companyId: string;
    companyCountry: Country;
    companyLocales: Locale[];
    branchId: string;
    branchLocales: Locale[];
};

export type DisplayDevicePublicClaims = BasePublicClaims & {
    role: 'displayDevice';
    deviceId: string;
    macAddress: string;
    companyId: string;
    branchId: string;
    groupName: string;
    screenResolution: DeviceScreenResolution;
    companyLocales: Locale[];
    branchLocales: Locale[];
    apbNumber?: string | null;
};

export type NoOnePublicClaims = BasePublicClaims & {
    role: '' | null;
};

interface DeviceSignalingPublicClaims {
    channel: string;
}

export type PublicClaims =
    | PlatformAdministratorPublicClaims
    | SupportAgentPublicClaims
    | CompanyManagerPublicClaims
    | BranchManagerPublicClaims
    | ResellerPublicClaims
    | DisplayDevicePublicClaims
    | NoOnePublicClaims;

export type IdentifiedCustomerClaims = ReservedClaims & {
    firstName: string;
    lastName: string;
    locale: Locale;
    branchId: string;
};

export default class JsonWebToken {
    public readonly value: string;
    public readonly refreshToken: string;

    public readonly claims: PublicClaims & DeviceSignalingPublicClaims = {
        userId: '',
        username: '',
        role: '',
        channel: '',
        canShareWithPlatform: false,
        canShareWithCountries: null,
        canUseSharingGroups: false,
        branchName: '',
        companyName: '',
    };

    public readonly expiration: number;

    constructor(value: string, refreshToken: string) {
        this.value = value;
        this.refreshToken = refreshToken;

        try {
            const payload = jwtDecode<ReservedClaims & PublicClaims & DeviceSignalingPublicClaims>(value);

            this.expiration = payload.exp;
            this.claims.userId = payload.sub;
            this.claims.username = payload.username;
            this.claims.role = payload.role;
            this.claims.canShareWithPlatform = payload.canShareWithPlatform;
            this.claims.canShareWithCountries = payload.canShareWithCountries;
            this.claims.canUseSharingGroups = payload.canUseSharingGroups;
            this.claims.branchName = payload.branchName;
            this.claims.companyName = payload.companyName;
            this.claims.branchTimezone = payload.branchTimezone;
            this.claims.companyType = payload.companyType;

            if (
                (payload.role === 'companyAdministrator' && this.claims.role === 'companyAdministrator') ||
                (payload.role === 'branchAdministrator' && this.claims.role === 'branchAdministrator') ||
                (payload.role === 'displayDevice' && this.claims.role === 'displayDevice')
            ) {
                this.claims.companyId = payload.companyId;
                this.claims.companyLocales = payload.companyLocales;
            }

            if (
                (payload.role === 'companyAdministrator' && this.claims.role === 'companyAdministrator') ||
                (payload.role === 'branchAdministrator' && this.claims.role === 'branchAdministrator')
            ) {
                this.claims.companyCountry = payload.companyCountry;
            }

            if (
                (payload.role === 'branchAdministrator' && this.claims.role === 'branchAdministrator') ||
                (payload.role === 'displayDevice' && this.claims.role === 'displayDevice')
            ) {
                this.claims.branchId = payload.branchId;
                this.claims.branchLocales = payload.branchLocales;
            }

            if (payload.role === 'reseller' && this.claims.role === 'reseller') {
                this.claims.resellerId = payload.resellerId;
                this.claims.resellerCountry = payload.resellerCountry;
            }

            if (payload.role === 'displayDevice' && this.claims.role === 'displayDevice') {
                this.claims.apbNumber = payload.apbNumber;
                this.claims.macAddress = payload.macAddress;
            }

            if (payload.channel) {
                this.claims.channel = payload.channel;
            }
        } catch (e) {
            if (e instanceof JsonWebTokenException) {
                throw e;
            } else if ((e as Error).name === 'InvalidTokenError') {
                throw new JsonWebTokenException('Error decoding JWT token');
            } else {
                throw new JsonWebTokenException((e as Error).message);
            }
        }
    }

    public getRefreshTokenExpiryClaim(): number {
        if (!this.refreshToken) {
            throw new JsonWebTokenException('No refresh token');
        }

        try {
            const payload = jwtDecode<ReservedClaims>(this.refreshToken);
            return payload.exp;
        } catch (e) {
            if (e instanceof JsonWebTokenException) {
                throw e;
            } else if ((e as Error).name === 'InvalidTokenError') {
                throw new JsonWebTokenException('Error decoding JWT token');
            } else {
                throw new JsonWebTokenException((e as Error).message);
            }
        }
    }

    public static decodeIdentifiedCustomerToken(token: string) {
        return {
            value: token,
            claims: jwtDecode<IdentifiedCustomerClaims>(token),
        };
    }
}
