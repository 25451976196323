import * as React from 'react';

import { Route, anyAuthorization, CmsItem } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/terms-and-conditions',
        main: () => import('./CmsPage'),
        isAuthorized: anyAuthorization,
        preloadData: CmsItem.pageState.load('termsAndConditionsPage'),
        preloading: () => <FullPage bodyType="tabs-and-boxes" />,
    },
    {
        path: '/privacy-policy',
        main: () => import('./CmsPage'),
        isAuthorized: anyAuthorization,
        preloadData: CmsItem.pageState.load('privacyPolicyPage'),
        preloading: () => <FullPage bodyType="tabs-and-boxes" />,
    },
    {
        path: '/vision-promo',
        main: () => import('./CmsPage'),
        isAuthorized: anyAuthorization,
        preloadData: CmsItem.pageState.load('visionPromoMessage'),
        preloading: () => <FullPage bodyType="tabs-and-boxes" />,
    },
    {
        path: '/matic-promo',
        main: () => import('./CmsPage'),
        isAuthorized: anyAuthorization,
        preloadData: CmsItem.pageState.load('vendingMachinePromoMessage'),
        preloading: () => <FullPage bodyType="tabs-and-boxes" />,
    },
    {
        path: '/webshop-promo',
        main: () => import('./CmsPage'),
        isAuthorized: anyAuthorization,
        preloadData: CmsItem.pageState.load('webshopPromoMessage'),
        preloading: () => <FullPage bodyType="tabs-and-boxes" />,
    },
    {
        path: '/vending-machine-privacy-policy/:branchId',
        main: () => import('./VendingMachinePrivacyPolicy'),
        isAuthorized: anyAuthorization,
        preloadData: CmsItem.pageState.load('vendingMachinePrivacyPolicy'),
        preloading: () => <FullPage bodyType="tabs-and-boxes" />,
    },
];

export default routes;
