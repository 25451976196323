import { Domain, RoomService, Infrastructure } from 'api';

type ConnectHandler = () => void;
type DisconnectHandler = () => void;
type HardwareStatusMessageHandler = (status: Domain.NightHatchHardwareStatus) => void;
type RobotProductDeliveryStatusMessageHandler = (status: Domain.NightHatchRobotProductDeliveryStatus) => void;
type CartProductsStockUpdateMessageHandler = (stockUpdate: Domain.NightHatchCartProductsStockUpdate) => void;
type SimulatedTransactionStatusMessageHandler = (status: Domain.NightHatchSimulatedTransactionStatusMessage) => void;
type BarcodeScannedMessageHandler = (message: Domain.NightHatchScannedBarcodeMessage) => void;
type ConfirmCustomerDeliveryActionHandler = (message: Domain.NightHatchConfirmCustomerDeliveryActionMessage) => void;
type ChoosePaymentOptionActionHandler = (message: Domain.NightHatchChoosePaymentOptionActionMessage) => void;

export class PlatformSideApi {
    private readonly peer: RoomService.Peer;
    private tunnel: RoomService.NightHatchTunnel | undefined;
    private connectHandler: ConnectHandler;
    private disconnectHandler: DisconnectHandler;
    private hardwareStatusMessageHandler: HardwareStatusMessageHandler;
    private robotProductDeliveryStatusMessageHandler: RobotProductDeliveryStatusMessageHandler;
    private cartProductsStockUpdateMessageHandler: CartProductsStockUpdateMessageHandler;
    private simulatedTransactionStatusMessageHandler: SimulatedTransactionStatusMessageHandler;
    private barcodeScannedMessageHandler: BarcodeScannedMessageHandler | undefined;
    private confirmCustomerDeliveryActionHandler: ConfirmCustomerDeliveryActionHandler | undefined;
    private choosePaymentOptionActionHandler: ChoosePaymentOptionActionHandler | undefined;

    public constructor(
        id: string,
        deviceId: string,
        jwt: string,
        metaData: RoomService.PeerInfo['peerMeta'],
        onFailToConnect: (e: Event) => void,
    ) {
        this.peer = new RoomService.Peer(
            id,
            {
                signalingServerHost: Infrastructure.Container.getConstant('deviceSignalingServerHost'),
                jwt,
                reconnect: false,
                info: {
                    peerMeta: metaData,
                },
            },
            onFailToConnect,
        );

        const handlePeerConnected = () => {
            const disconnectHandler = () => {
                if (this.tunnel?.isConnected()) {
                    this.tunnel?.removeEventListener('connected', connectedHandler);
                    this.tunnel?.removeEventListener('disconnected', disconnectHandler);
                    this.tunnel?.close();
                }
                if (this.peer.isConnected()) {
                    this.peer.disconnect();
                }
                if (this.disconnectHandler) {
                    this.disconnectHandler();
                }
            };

            this.tunnel = new RoomService.NightHatchTunnel(this.peer, deviceId, {
                tunnelSide: 'initiate',
                doNotRetryConnection: true,
                onConnectionFailure: e => {
                    disconnectHandler();
                    if (onFailToConnect) {
                        onFailToConnect(e);
                    }
                },
            });

            const connectedHandler = () => {
                if (this.connectHandler) {
                    this.connectHandler();
                }

                this.tunnel?.removeEventListener('connected', connectedHandler);
            };

            this.tunnel.addEventListener('connected', connectedHandler);
            this.tunnel.addEventListener('message', this.handleMessage);
            this.tunnel.addEventListener('disconnected', disconnectHandler);
        };

        this.peer.onFirstConnect(handlePeerConnected);
    }

    public isConnected(): boolean {
        return !!this.tunnel?.isConnected();
    }

    public async getHatchPhoto(): Promise<Domain.NightHatchGetHatchPhotoDataMessage['data']> {
        return new Promise(resolve => {
            const handleResponse = (event: MessageEvent) => {
                const message: Domain.NightHatchTunnelMessage = JSON.parse(event.data);

                if (message.type === 'getHatchPhotoData') {
                    this.tunnel?.removeEventListener('message', handleResponse);
                    resolve(message.data);
                }
            };

            this.tunnel?.addEventListener('message', handleResponse);

            this.tunnel?.send({
                tunnel: true,
                type: 'getHatchPhoto',
            });
        });
    }

    public async getScreenPhoto(): Promise<Domain.NightHatchGetScreenPhotoDataMessage['data']> {
        return new Promise(resolve => {
            const handleResponse = (event: MessageEvent) => {
                const message: Domain.NightHatchTunnelMessage = JSON.parse(event.data);

                if (message.type === 'getScreenPhotoData') {
                    this.tunnel?.removeEventListener('message', handleResponse);
                    resolve('data:image/png;base64, ' + message.data);
                }
            };

            this.tunnel?.addEventListener('message', handleResponse);

            this.tunnel?.send({
                tunnel: true,
                type: 'getScreenPhoto',
            });
        });
    }

    public async getStockForProducts(
        products: Domain.ProductCodes[],
    ): Promise<Domain.NightHatchProductStockInquiryResultsMessage['results']> {
        return new Promise(resolve => {
            const handleResponse = (event: MessageEvent) => {
                const message: Domain.NightHatchTunnelMessage = JSON.parse(event.data);

                if (message.type === 'productStockInquiryResults') {
                    this.tunnel?.removeEventListener('message', handleResponse);
                    resolve(message.results);
                }
            };

            this.tunnel?.addEventListener('message', handleResponse);

            this.tunnel?.send({
                tunnel: true,
                type: 'productStockInquiry',
                products,
            });
        });
    }

    public syncState(state: Domain.NightHatchState) {
        this.tunnel?.send({
            tunnel: true,
            type: 'state',
            state,
        });
    }

    public sendTurnHatchLightOn() {
        this.tunnel?.send({
            tunnel: true,
            type: 'hardwareAction',
            action: 'hatchLightOn',
        });
    }

    public sendTurnHatchLightOff() {
        this.tunnel?.send({
            tunnel: true,
            type: 'hardwareAction',
            action: 'hatchLightOff',
        });
    }

    public sendDeliverProductsFromRobot(items: Domain.NightHatchDeliverProductsFromRobotOrLockersItems, outputDestination?: number) {
        this.tunnel?.send({
            tunnel: true,
            type: 'deliverProductsFromRobot',
            items,
            outputDestination,
        });
    }

    public sendRequestIdScanStart() {
        this.tunnel?.send({
            tunnel: true,
            type: 'requestIdScan',
            isRequestStart: true,
        });
    }

    public sendRequestIdScanStop() {
        this.tunnel?.send({
            tunnel: true,
            type: 'requestIdScan',
            isRequestStart: false,
        });
    }

    public sendInject() {
        this.tunnel?.send({
            tunnel: true,
            type: 'hardwareAction',
            action: 'inject',
        });
    }

    public sendReject() {
        this.tunnel?.send({
            tunnel: true,
            type: 'hardwareAction',
            action: 'reject',
        });
    }

    public sendOpenOutsideHatch() {
        this.tunnel?.send({
            tunnel: true,
            type: 'hardwareAction',
            action: 'openOutsideHatch',
        });
    }

    public sendCloseOutsideHatch() {
        this.tunnel?.send({
            tunnel: true,
            type: 'hardwareAction',
            action: 'closeOutsideHatch',
        });
    }

    public sendOpenInsideHatch() {
        this.tunnel?.send({
            tunnel: true,
            type: 'hardwareAction',
            action: 'openInsideHatch',
        });
    }

    public sendCloseInsideHatch() {
        this.tunnel?.send({
            tunnel: true,
            type: 'hardwareAction',
            action: 'closeInsideHatch',
        });
    }

    public sendStartWaitingForTransaction(
        amountInEuroCents: number,
        paymentMethod: Domain.DeviceTransactionPaymentMethod,
        payUrl?: string,
    ) {
        this.tunnel?.send({
            tunnel: true,
            type: 'startTerminalTransaction',
            amountInEuroCents,
            paymentMethod,
            payUrl,
        });
    }
    public sendChoosePaymentOption() {
        this.tunnel?.send({
            tunnel: true,
            type: 'choosePaymentOption',
        });
    }
    public sendStopWaitingForTransaction() {
        this.tunnel?.send({
            tunnel: true,
            type: 'stopTerminalTransaction',
        });
    }

    public sendInitiateVideoCall() {
        this.tunnel?.send({
            tunnel: true,
            type: 'initiateVideoCall',
        });
    }

    public sendOpenCollectLocker(moduleId: string, lockerId: string) {
        this.tunnel?.send({
            tunnel: true,
            type: 'openCollectLocker',
            moduleId,
            lockerId,
        });
    }

    public onConnect(handler: ConnectHandler) {
        this.connectHandler = handler;
        if (this.tunnel?.isConnected()) {
            this.connectHandler();
        }
    }

    public onDisconnect(handler: DisconnectHandler) {
        this.disconnectHandler = handler;
    }

    public onHardwareStatusChange(handler: HardwareStatusMessageHandler) {
        this.hardwareStatusMessageHandler = handler;
    }

    public onRobotProductDeliveryStatus(handler: RobotProductDeliveryStatusMessageHandler) {
        this.robotProductDeliveryStatusMessageHandler = handler;
    }

    public onCartProductsStockUpdateMessage(handler: CartProductsStockUpdateMessageHandler) {
        this.cartProductsStockUpdateMessageHandler = handler;
    }

    public onSimulatedTransactionStatusMessage(handler: SimulatedTransactionStatusMessageHandler) {
        this.simulatedTransactionStatusMessageHandler = handler;
    }

    public onBarcodeScannedMessage(handler?: BarcodeScannedMessageHandler) {
        this.barcodeScannedMessageHandler = handler;
    }

    public onConfirmCustomerDeliveryActionMessage(handler: ConfirmCustomerDeliveryActionHandler) {
        this.confirmCustomerDeliveryActionHandler = handler;
    }

    public onChoosePaymentOptionActionMessage(handler: ChoosePaymentOptionActionHandler) {
        this.choosePaymentOptionActionHandler = handler;
    }

    private handleMessage = (event: MessageEvent) => {
        const message: Domain.NightHatchTunnelMessage = JSON.parse(event.data);

        if (message.type === 'hardwareStatus' && this.hardwareStatusMessageHandler) {
            this.hardwareStatusMessageHandler(message.status);
        } else if (message.type === 'robotProductDeliveryStatus' && this.robotProductDeliveryStatusMessageHandler) {
            this.robotProductDeliveryStatusMessageHandler(message.deliveryStatus);
        } else if (message.type === 'cartProductsStockUpdate' && this.cartProductsStockUpdateMessageHandler) {
            this.cartProductsStockUpdateMessageHandler(message.stockUpdate);
        } else if (message.type === 'simulatedTransactionStatus' && this.simulatedTransactionStatusMessageHandler) {
            this.simulatedTransactionStatusMessageHandler(message);
        } else if (message.type === 'scannedBarcode' && this.barcodeScannedMessageHandler) {
            this.barcodeScannedMessageHandler(message);
        } else if (message.type === 'confirmCustomerDeliveryAction' && this.confirmCustomerDeliveryActionHandler) {
            this.confirmCustomerDeliveryActionHandler(message);
        } else if (message.type === 'choosePaymentOptionAction' && this.choosePaymentOptionActionHandler) {
            this.choosePaymentOptionActionHandler(message);
        }
    };

    public close() {
        if (this.tunnel?.isConnected()) {
            this.tunnel?.close();
        }

        if (this.peer.isConnected()) {
            this.peer.disconnect();
        }
    }
}
