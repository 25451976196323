import { Domain } from 'api';

import { customerApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type CustomerProps = keyof Domain.Customer;

export const defaultSorting: Domain.Sorting<CustomerProps> = {
    field: 'lastName',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.Customer, CustomerProps>({
    getState: rootState => rootState.customer.overview,
});

export const reducerActions = makeReducerActions<Domain.Customer, CustomerProps>({
    reducerPrefix: '@customer/overview',
});

export const actions = makeActions<Domain.Customer, CustomerProps>({
    dataTableSaveKey: 'customersOverview-v5',
    loadApi: options => {
        const branchId = options.ownershipIds.branchId;
        if (!branchId) {
            throw new Error('Cannot load Customers if no Branch specified');
        }
        return customerApi.GetBranchCustomers(branchId, options.pagination, options.sorting, options.filters, options.search);
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/branch/customers',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Customer, CustomerProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
