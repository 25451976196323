import {
    Wwks2CommClient
} from './index';
import {
    wwks2StockInfoResponse,
    wwks2StockInfoMessage,
    wwks2StatusRequest,
    wwks2OutputResponse,
    wwks2OutputMessage,
    wwks2StatusResponse,
    Wwks2ResultData
} from '../../Domain';
import { Wwks2CommSettings } from './Wwks2CommSettings';
import * as Wwks2Domain from '../../Domain/Wwks2';

export default class Wwks2Comm {
    public onCloseConnection: (event: CloseEvent) => void
    public onOpenConnection: () => void
    public onStatusChange: (data: wwks2StatusResponse) => void
    public onStatusRequest: (data: wwks2StatusRequest) => void
    public onStockChange: (data: wwks2StockInfoMessage) => void

    private readonly _client: Wwks2CommClient;
    private readonly _connectionString: string;

    public constructor(wwks2CommSettings: Wwks2CommSettings) {
        this._connectionString = wwks2CommSettings.ConnectionString
        this._client = new Wwks2CommClient();

        this.SetSourceId(wwks2CommSettings.SourceId);
        this.SetStockLocation(wwks2CommSettings.StockLocations);
        this.SetZone(wwks2CommSettings.Zone);
        this.SetRobotExit(wwks2CommSettings.Exit);

        window.addEventListener('beforeunload', () => {
            this._client.closeConnection();
        });
    }

    public SetSourceId(sourceId: number) {
        this._client.SetSourceId(sourceId);
    }

    public SetRobotExit(robotExit: number) {
        this._client.SetRobotExit(robotExit);
    }

    public SetStockLocation(stockLocation: Array<string>) {
        this._client.SetStockLocation(stockLocation);
    }

    public SetZone(zone: string | undefined) {
        this._client.SetZone(zone);
    }

    public async DeleteProcessStatus(dialogId: string | number){
        return this._client.DeleteProcessedDialogData(dialogId);
    }

    public GetProcessStatus(dialogId: string | number){
        return this._client.GetProcessedDialogData(dialogId);
    }

    public async Connect(
        reconnect: boolean = false,
        waitUntilConnected: boolean = false
    ): Promise<{status: boolean, error: string, connectionStatus: string}> {
        return await this._client.Listen(this._connectionString, (error) => { console.log(error) }, reconnect, waitUntilConnected);
    }

    public async CheckProductStock(barcodes: string[], reservationId = '', deleteWWks2Dialog = true): Promise<{ articles: { barcode: string, quantity: string, availableQuantity: string }[], status: string, dialogId: string | number }> {
        let stockInfoResponse: Wwks2Domain.Wwks2ResultData | boolean = false;
        stockInfoResponse = await this.GetStockInfo(barcodes, reservationId);

        const stockResult: {
            articles: { barcode: string, quantity: string, availableQuantity: string }[],
            status: string,
            dialogId: number | string
        } = {
            articles: [],
            status: 'aborted',
            dialogId: 0
        }

        if (typeof stockInfoResponse !== 'boolean') {
            
            if (stockInfoResponse.status.status) {
                stockResult.articles = (stockInfoResponse.data as Wwks2Domain.wwks2StockInfoResponse).articles.map(article => {
                    let availableQuantity = 0;
                    if (typeof article.availablePacks !== 'undefined' && 0 < article.availablePacks.length) {
                        article.availablePacks.forEach(pack => {
                            if ((typeof pack.stockLocationId !== 'undefined' &&
                                ((this._client.GetStockLocation().includes(pack.stockLocationId)) || (this._client.GetZone() === pack.stockLocationId))) &&
                                ('undefined' === typeof pack.reserved || 'False' === pack.reserved)) {
                                availableQuantity++;
                            }
                        });

                        return { barcode: article.id, quantity: availableQuantity.toString(), availableQuantity: availableQuantity.toString() };
                    }
                    
                    return { barcode: article.id, quantity: article.quantity!, availableQuantity: article.quantity! };
                });

                stockResult.status = 'completed';
                
            } else if (stockInfoResponse.status.canceled) {
                stockResult.status = 'timeout';
            }
            stockResult.dialogId = stockInfoResponse.status.dialogId;
            if(deleteWWks2Dialog){
                await this.DeleteProcessStatus(stockInfoResponse.status.dialogId);
            }
        }
        
        return stockResult;
    }

    public async GetCompleteRobotStock(includePacks = false, deleteWWks2Dialog = true){
        let stockInfoResponse: Wwks2Domain.Wwks2ResultData | boolean = false;
        stockInfoResponse = await this.GetStockInfo([],'', includePacks);

        const stockResult: {
            articles: { barcode: string, quantity: string, availableQuantity: string }[],
            status: string,
            dialogId: number | string
        } = {
            articles: [],
            status: 'aborted',
            dialogId: 0
        }

        if (typeof stockInfoResponse !== 'boolean') {
            if (stockInfoResponse.status.status) {
                stockResult.articles = (stockInfoResponse.data as Wwks2Domain.wwks2StockInfoResponse).articles.map(article => {
                    let quantity = '0';
                    // let availableQuantity = 0;
                    if(typeof article.quantity !== 'undefined'){
                        quantity = article.quantity;
                    }
                    // if (typeof article.availablePacks !== 'undefined') {
                    //     article.availablePacks.forEach(pack => {
                    //         if ((typeof pack.stockLocationId !== 'undefined' &&
                    //             ((this.settings.delivery && this.settings.delivery.stockLocation?.includes(pack.stockLocationId)) ||
                    //             (this.settings.delivery && (this.settings.delivery.zone === pack.stockLocationId))) &&
                    //             ('undefined' === typeof pack.reserved || 'False' === pack.reserved))) {
                    //             availableQuantity++;
                    //         }
                    //     });
                    // }

                    // quantity = availableQuantity?
                    return { barcode: article.id, quantity: quantity, availableQuantity: quantity };
                });
                stockResult.status = 'completed';
            } else if (stockInfoResponse.status.canceled) {
                stockResult.status = 'timeout';
            }
            stockResult.dialogId = stockInfoResponse.status.dialogId;
            if(deleteWWks2Dialog){
                await this.DeleteProcessStatus(stockInfoResponse.status.dialogId);
            }
        }
        return stockResult;
    }

    public GetRobotExit(): number {
        return this._client.GetRobotExit();
    }
    
    public async GetStockInfo(barcodes: string[], reservationId: string = '', includePacks: boolean = true): Promise<Wwks2ResultData> {
        const stockInfoStatus = await this._client.Wwks2StockInfo.sendAndProcessStockInfoRequest(barcodes, reservationId, includePacks);
        if (stockInfoStatus.status) {
            const stockInfoData = this._client.GetProcessedDialogData(stockInfoStatus.dialogId);
            if (typeof stockInfoData === 'boolean') {
                return {
                    status: {
                        dialogId: stockInfoStatus.dialogId,
                        status: false,
                        msg: 'no status dialog found',
                        dialogType: 'StockInfo',
                        isDialogType: 'response',
                        errorType: 'noStatusDialog'
                    },
                    data: false
                }
            } else if (stockInfoData.data.response === undefined) {
                return {
                    status: {
                        dialogId: stockInfoStatus.dialogId,
                        status: false,
                        msg: 'no response found',
                        dialogType: 'ArticleInfo',
                        isDialogType: 'response',
                        errorType: 'noResponseFound'
                    },
                    data: false
                }
            } else {
                return {
                    status: stockInfoStatus,
                    data: (stockInfoData.data.response as wwks2StockInfoResponse)
                };
            }
        } else {
            return {
                status: stockInfoStatus,
                data: false
            };
        }
    }

    public async EjectProduct(barcodes: { barcode: string, quantity: number }[], deleteWWks2Dialog = true) {
        let ejectResponse;

        const ejectResult: { status: 'completed' | 'incomplete' | 'aborted' | 'queued' | 'rejected' | 'timeout' | 'notDeliverble' | 'robotStorageNotReady' |'wwks2ConnectionNotReady', serialNumbers: { barcode: string, serialNumbers: string[] }[], outputDestination: number, articles: Wwks2Domain.wwks2Article[], dialogId: number | string } = {
            status: 'rejected',
            serialNumbers: [],
            outputDestination: 0,
            articles: [],
            dialogId: 0
        };

        const allBarcodesCanBeDelivered = true;
        if(!allBarcodesCanBeDelivered){
            ejectResult.status = 'notDeliverble';
            return ejectResult;
        }

        ejectResponse = await this.EjectArticles(barcodes);

        if (typeof ejectResponse !== 'undefined') {
            if (ejectResponse.status.status) {
                const outputResult = (ejectResponse.data as Wwks2Domain.wwks2OutputResult);
                ejectResult.articles = outputResult.articles;
                ejectResult.outputDestination = outputResult.outputDestination;
                ejectResult.status = outputResult.status;
                outputResult.articles.forEach(article => {
                    if (typeof article.packs !== 'undefined') {
                        const serialNumbers: string[] = [];
                        article.packs.forEach(pack => {
                            if (typeof pack.scanCode !== 'undefined') {
                                serialNumbers.push(pack.scanCode);
                            }
                        });
                        ejectResult.serialNumbers.push({ barcode: article.id, serialNumbers: serialNumbers });
                    }
                });
            } else if (ejectResponse.status.canceled) {
                ejectResult.status = 'timeout';
            }
            ejectResult.dialogId = ejectResponse.status.dialogId;

            if(deleteWWks2Dialog){
                await this.DeleteProcessStatus(ejectResponse.status.dialogId);
            }

        }

        return ejectResult;
    }

    public async EjectArticles(barcodes: any[]): Promise<Wwks2ResultData> {
        const outputStatus = await this._client.Wwks2Output.sendAndProcessOutputRequest(barcodes);

        if (outputStatus.status) {
            const outputInfo = this._client.GetProcessedDialogData(outputStatus.dialogId);
            if (typeof outputInfo === 'boolean') {
                return {
                    status: {
                        dialogId: outputStatus.dialogId,
                        status: false,
                        msg: 'no status dialog found',
                        dialogType: 'Output',
                        isDialogType: 'response',
                        errorType: 'noStatusDialog'
                    },
                    data: false
                }
            } else if (outputInfo.data.response === undefined) {
                return {
                    status: {
                        dialogId: outputStatus.dialogId,
                        status: false,
                        msg: 'no response found',
                        dialogType: 'Output',
                        isDialogType: 'response',
                        errorType: 'noResponseFound'
                    },
                    data: false
                }
            } else if (outputInfo.data.message === undefined) {
                return {
                    status: outputStatus,
                    data: (outputInfo.data.response as wwks2OutputResponse)
                };
            } else {
                return {
                    status: outputStatus,
                    data: (outputInfo.data.message as wwks2OutputMessage)
                };
            }
        } else {
            return {
                status: outputStatus,
                data: false
            };
        }
    }

    public async StatusInfo(IncludeDetails: boolean = false): Promise<Wwks2ResultData> {
        const statusInfoStatus = await this._client.Wwks2Status.sendAndParseStatusRequest(IncludeDetails);
        if (statusInfoStatus.status) {
            const statusInfoData = this._client.GetProcessedDialogData(statusInfoStatus.dialogId);
            if (typeof statusInfoData === 'boolean') {
                return {
                    status: {
                        dialogId: statusInfoStatus.dialogId,
                        status: false,
                        msg: 'no status dialog found',
                        dialogType: 'Status',
                        isDialogType: 'response',
                        errorType: 'noStatusDialog'
                    },
                    data: false
                }
            } else if (statusInfoData.data.response === undefined) {
                return {
                    status: {
                        dialogId: statusInfoStatus.dialogId,
                        status: false,
                        msg: 'no response found',
                        dialogType: 'Status',
                        isDialogType: 'response',
                        errorType: 'noResponseFound'
                    },
                    data: false
                }
            } else {
                return {
                    status: statusInfoStatus,
                    data: statusInfoData.data.response as wwks2StatusResponse
                }
            }
        } else {

            return {
                status: statusInfoStatus,
                data: false
            }
        }
    }

    public SetOnStockChangeCallback(callback: (data: wwks2StockInfoMessage) => void) {
        this.onStockChange = callback;
        this._client.Wwks2StockInfo.setOnStockChangeCallback(this.onStockChange);
    }

    public SetOnStatusRequestCallback(callback: (data: wwks2StatusRequest) => void) {
        this.onStatusRequest = callback;
        this._client.Wwks2Status.setOnStatusRequestCallback(this.onStatusRequest);
    }

    public async closeConnection(reconnecting: boolean = false): Promise<boolean> {
        return await this._client.closeConnection(reconnecting);
    }

    public SetOnOpenConnectionCallback(callback: () => void) {
        this.onOpenConnection = callback;
        this._client.SetOnConnectionOpen(this.onOpenConnection);
    }

    public SetOnCloseConnectionCallback(callback: (event: CloseEvent) => void) {
        this.onCloseConnection = callback;
        this._client.SetOnConnectionClose(this.onCloseConnection);
    }

    public SetOnStatusChangeCallback(callback: (data: wwks2StatusResponse) => void) {
        this.onStatusChange = callback;
        this._client.Wwks2Status.setOnStatusChangeCallback(this.onStatusChange);
    }
}