import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain, Infrastructure } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { productSelectionApi, stockSourceApi, scientificCategoryApi, importApi } from '@/api';
import { selectLoggedInCompanyOrBranchManagerOwnershipIds, selectLoggedInUserOwnership } from '@/Authentication';
import { selectLoggedInCompanyOrBranchManagerCountry } from '@/Authentication/selectors';
import * as categoriesTreeState from '@/AvailableProduct/categoriesTreeState';
import * as importErrorsState from '@/Import/importErrorsState';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

const EMPTY_SELECTION: Domain.ProductSelection = {
    categoryIdsToBeExcluded: [],
    organisationIdsToBeExcluded: [],
    brandIdsToBeExcluded: [],
    activeIngredientIdsToBeExcluded: [],
    scientificCategoryIdsToBeExcluded: [],
    productIdsToBeExcluded: [],
    stockSourceIdsToBeIncluded: [],
    conservationsToBeExcluded: [],
    productIdsToBeIncluded: [],
    productIdsToBeIncludedForRobotHomeScreen: [],
    productIdsToBeIncludedFromImport: [],
};

export type ScientificCategoryTrees = {
    [key in Domain.ScientificCategorySource]?: Domain.ScientificCategory;
};

export interface State {
    selectionType: Domain.ProductSelectionType;
    productAvailabilityIntegrations: Domain.StockSourceIds;
    scientificCategoryTrees: ScientificCategoryTrees;
    selection?: Domain.ProductSelection;
    lastProductImport?: Domain.ImportDetails;
}

const initialState: State = {
    selectionType: 'inStoreProductSelection',
    productAvailabilityIntegrations: [],
    scientificCategoryTrees: {},
};

const reducerActions = {
    setSelectionType: createAction('@productSelection/update/setSelectionType', withPayloadType<Domain.ProductSelectionType>()),
    setProductAvailabilityIntegrations: createAction(
        '@productSelection/update/setProductAvailabilityIntegrations',
        withPayloadType<Domain.StockSourceIds>(),
    ),
    setSelection: createAction('@productSelection/update/setSelection', withPayloadType<Domain.ProductSelection>()),
    setScientificCategoryTrees: createAction(
        '@productSelection/update/setScientificCategoryTrees',
        withPayloadType<ScientificCategoryTrees>(),
    ),
    setLastProductImport: createAction('@productSelection/update/setLastProductImport', withPayloadType<Domain.ImportDetails>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setSelectionType, (state, action) => {
            state.selectionType = action.payload;
        })
        .addCase(reducerActions.setProductAvailabilityIntegrations, (state, action) => {
            state.productAvailabilityIntegrations = action.payload;
        })
        .addCase(reducerActions.setSelection, (state, action) => {
            state.selection = action.payload;
        })
        .addCase(reducerActions.setScientificCategoryTrees, (state, action) => {
            state.scientificCategoryTrees = action.payload;
        })
        .addCase(reducerActions.setLastProductImport, (state, action) => {
            state.lastProductImport = action.payload;
        }),
);

export const selectSelectionType: Selector<RootState, Domain.ProductSelectionType> = state => state.productSelection.update.selectionType;

export const selectProductAvailabilityIntegrations: Selector<RootState, Domain.StockSourceIds> = state => {
    const integrations = state.productSelection.update.productAvailabilityIntegrations;

    if (!integrations) {
        throw new Error('Product Availability Integrations not loaded');
    }

    return integrations;
};

export const selectScientificCategoryTrees: Selector<RootState, ScientificCategoryTrees> = state =>
    state.productSelection.update.scientificCategoryTrees;

export const maybeSelectProductSelection: Selector<RootState, Domain.ProductSelection | undefined> = state =>
    state.productSelection.update.selection;

export const selectProductSelection: Selector<RootState, Domain.ProductSelection> = state => {
    const selection = maybeSelectProductSelection(state);
    if (!selection) {
        throw new Error('Selection not loaded');
    }
    return selection;
};

export const selectLastProductImport: Selector<RootState, Domain.ImportDetails | undefined> = state =>
    state.productSelection.update.lastProductImport;

export const loadProductAvailabilityIntegrations =
    (companyId: Domain.Company['companyId'], branchId: Domain.Branch['branchId']): ThunkAction =>
    async dispatch => {
        const integrations = await stockSourceApi.GetStockSourceIds(companyId, branchId, { stockTypes: 'inHouse' });
        await dispatch(reducerActions.setProductAvailabilityIntegrations(integrations));
    };

export const loadSelection =
    (ownership: Domain.Ownership, selectionType: Domain.ProductSelectionType): ThunkAction =>
    async dispatch => {
        try {
            const selection = await productSelectionApi.GetProductSelection(ownership, selectionType);
            dispatch(reducerActions.setSelection(selection));
        } catch (e) {
            if (e instanceof Infrastructure.Api.HttpApiException && e.status === 404) {
                dispatch(
                    reducerActions.setSelection({
                        ...EMPTY_SELECTION,
                    }),
                );
            } else {
                throw e;
            }
        }
    };

export const loadSelectionProductIdsToBeIncludedFromImport =
    (
        ownership: Domain.Ownership,
        selectionType: Domain.ProductSelectionType,
    ): ThunkAction<Promise<Domain.ProductSelection['productIdsToBeIncludedFromImport']>> =>
    async (_1, getState) => {
        const state = getState();
        const existingSelection = maybeSelectProductSelection(state);

        try {
            const newSelection = await productSelectionApi.GetProductSelection(ownership, selectionType);
            if (newSelection) {
                return newSelection.productIdsToBeIncludedFromImport || [];
            }
        } catch (e) {
            if (e instanceof Infrastructure.Api.HttpApiException && e.status === 404) {
                // ignore
            } else {
                throw e;
            }
        }

        return existingSelection?.productIdsToBeIncludedFromImport || [];
    };

export const loadScientificCategoryTrees =
    (country: Domain.Country): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const scientificCategoriesSources = state.localisation.availableCountries.countries[country]
            ? state.localisation.availableCountries.countries[country].scientificCategories
            : undefined;
        if (scientificCategoriesSources) {
            const categories = await Promise.all(
                scientificCategoriesSources.map(source => {
                    return scientificCategoryApi.GetScientificCategoriesTree(source);
                }),
            );

            const trees: ScientificCategoryTrees = {};
            let sourceIndex = 0;
            for (const source of scientificCategoriesSources) {
                trees[source] = categories[sourceIndex];
                sourceIndex += 1;
            }

            await dispatch(reducerActions.setScientificCategoryTrees(trees));
        }
    };

export const loadLastProductsImport = (): ThunkAction<Promise<Domain.ImportDetails | undefined>> => async (dispatch, getState) => {
    const state = getState();
    const selectionType = selectSelectionType(state);

    const imports = await importApi.GetCsvImports(
        {
            size: 5,
            page: 1,
        },
        {
            field: 'initializedOn',
            direction: 'descending',
        },
        { importType: 'productSelectionIncludedProductsImport' },
    );

    const selectionImports = imports.items.filter(importDetails => importDetails.details?.selectionType === selectionType);

    if (selectionImports.length > 0) {
        const lastImport = await importApi.GetCsvImportDetail(selectionImports[0].importId);
        if (lastImport) {
            dispatch(reducerActions.setLastProductImport(lastImport));
            dispatch(importErrorsState.actions.setPageSize(5));
            dispatch(importErrorsState.actions.setPaginationPage(1));
            dispatch(importErrorsState.actions.load(lastImport.importId));
            return lastImport;
        }
    }

    return undefined;
};

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const companyOrBranchManagerOwnershipIds = selectLoggedInCompanyOrBranchManagerOwnershipIds(state);
        const ownership = selectLoggedInUserOwnership(state);
        const country = selectLoggedInCompanyOrBranchManagerCountry(state);

        if (ownership.type !== 'branch' && ownership.type !== 'company') {
            throw new Error('Wrong ownership');
        }

        const type = options.urlParams.type;
        if (Domain.ProductSelectionTypes.indexOf(type) === -1) {
            throw new Error('Wrong selection type');
        }

        dispatch(reducerActions.setSelectionType(type));

        await Promise.all([
            ownership.type === 'branch'
                ? dispatch(loadProductAvailabilityIntegrations(companyOrBranchManagerOwnershipIds.companyId, ownership.ownerId))
                : undefined,
            dispatch(categoriesTreeState.actions.load(options)),
            dispatch(loadScientificCategoryTrees(country)),
            dispatch(loadSelection(ownership, type)),
            dispatch(loadLastProductsImport()),
        ]);
    };
