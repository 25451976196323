import { Pagination, Sorting, CustomersPage, CustomerDetails, UpdateCustomerPayload } from '@/Domain';

import Endpoint from './Endpoint';

export default class CustomerEndpoint extends Endpoint {
    public async GetBranchCustomers(
        branchId: string,
        pagination: Pagination,
        sorting: Sorting,
        filters?: {
            locale?: string;
        },
        search?: string,
    ): Promise<CustomersPage> {
        const response = await this.client.get({
            url: `/identified-customer/overview/${branchId}`,
            params: {
                pagination,
                sorting,
                search,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/CustomersPage', response);

        return response;
    }

    public async GetCustomerDetails(customerId: string): Promise<CustomerDetails> {
        const response = await this.client.get({
            url: `/identified-customer/details/${customerId}`,
        });

        this.guardResponseSchema('#/definitions/CustomerDetails', response);

        return response;
    }

    public async RenewIdentificationAndGetCustomerDetails(customerId: string): Promise<CustomerDetails> {
        const response = await this.client.get({
            url: `/identified-customer/details/${customerId}?withRenewedIdentificationSecret=true`,
        });

        this.guardResponseSchema('#/definitions/CustomerDetails', response);

        return response;
    }

    public async UpdateCustomer(payload: UpdateCustomerPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateIdentifiedCustomer',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Customer\\IdentifiedCustomer\\UpdateIdentifiedCustomer\\UpdateIdentifiedCustomer',
                payload,
            },
        });
    }

    public async SendIdentificationQrToCustomer(payload: { customerId: string }): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/SendIdentificationQrToCustomer',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Customer\\IdentifiedCustomer\\SendIdentificationQrToCustomer\\SendIdentificationQrToCustomer',
                payload,
            },
        });
    }
    public GetCustomersExportDownloadURL({ branchIdString, exportType }: { branchIdString: string; exportType: 'excel' | 'csv' }): string {
        const params = {
            filters: {
                goodieBagPickupStatus: 'unused',
            },
        };
        return this.client.buildApiURL(`/identified-customer/export/${exportType}/${branchIdString}`, true, params);
    }
}
