import {
    Pagination,
    Ownership,
    Locale,
    ProductSelection,
    ProductSelectionType,
    SelectedProductDetails,
    CustomProductDetails,
    SelectedProductsPage,
    ProductCompletenessFilter,
    SelectedProductType,
    BulkProductDetailsList,
    Sorting,
} from '@/Domain';

import Endpoint from './Endpoint';

interface SelectedProductsFilters {
    productCompleteness?: string;
    productImageShotType?: string;
    productIds?: string;
    productCodes?: string;
    hasImportedPrice?: string;
    hasImportedPromoPrice?: string;
    hasOverridePrice?: string;
    hasOverridePromoPrice?: string;
    isQuota?: string;
    categoryIds?: string;
    organisationIds?: string;
    stockSourceIds?: string;
    brandIds?: string;
    includeForIdentifiedCustomers?: string;
}

export default class ProductSelectionEndpoint extends Endpoint {
    public async GetProductSelection(ownership: Ownership, selectionType: ProductSelectionType): Promise<ProductSelection> {
        if (ownership.type !== 'branch') {
            throw new Error('Wrong ownership');
        }

        const response = await this.client.get({
            url: `/${ownership.type}/${ownership.ownerId}/selection-rules/${selectionType}`,
        });

        this.guardResponseSchema('#/definitions/ProductSelection', response);

        return response;
    }

    public async GetSelectedProducts(
        ownership: Ownership & {
            asSuperUser?: boolean;
        },
        selectionType: ProductSelectionType,
        pagination: Pagination,
        locale: Locale,
        search?: string,
        productCompleteness?: ProductCompletenessFilter,
        productType?: SelectedProductType,
        filters?: SelectedProductsFilters,
        sorting?: Sorting<any>,
    ): Promise<SelectedProductsPage> {
        if (ownership.type !== 'company' && ownership.type !== 'branch') {
            throw new Error('Unsupported ownership type');
        }

        let url = `/selected-product/${ownership.type}/overview/${selectionType}`;
        if (ownership.asSuperUser) {
            if (ownership.type !== 'branch') {
                throw new Error('Unsupported ownership type');
            }

            url = `/selected-product/branch/${ownership.ownerId}/overview/${selectionType}`;
        }

        const response = await this.client.get({
            url,
            params: {
                pagination,
                selectionType,
                locale,
                search,
                productCompleteness,
                productType,
                sorting,
                filters: this.serialiseSelectedProductsFilters(filters),
            },
        });

        this.guardResponseSchema('#/definitions/SelectedProductsPage', response);

        return response;
    }

    private serialiseSelectedProductsFilters(filters?: SelectedProductsFilters) {
        return filters
            ? {
                  ...filters,
                  productCompleteness: filters.productCompleteness === '' ? undefined : filters.productCompleteness,
                  productImageShotType: filters.productImageShotType === '' ? undefined : filters.productImageShotType,
                  productIds: filters.productIds ? filters.productIds.split(',') : undefined,
                  productCodes: filters.productCodes ? filters.productCodes : undefined,
                  hasImportedPrice: filters.hasImportedPrice === 'true' ? true : undefined,
                  hasImportedPromoPrice: filters.hasImportedPromoPrice === 'true' ? true : undefined,
                  hasOverridePrice: filters.hasOverridePrice === 'true' ? true : undefined,
                  hasOverridePromoPrice: filters.hasOverridePromoPrice === 'true' ? true : undefined,
                  isQuota: filters.isQuota === 'true' ? true : undefined,
                  categoryIds: filters.categoryIds ? filters.categoryIds.split(',') : undefined,
                  organisationIds: filters.organisationIds ? filters.organisationIds.split(',') : undefined,
                  stockSourceIds: filters.stockSourceIds ? filters.stockSourceIds.split(',') : undefined,
                  brandIds: filters.brandIds ? filters.brandIds.split(',') : undefined,
              }
            : {};
    }

    public async GetSelectedProductDetails(
        ownership: Ownership,
        productId: string,
        selectionType: ProductSelectionType,
        locale?: Locale,
    ): Promise<SelectedProductDetails | CustomProductDetails> {
        if (ownership.type !== 'company' && ownership.type !== 'branch') {
            throw new Error('Unsupported ownership type');
        }

        const response: SelectedProductDetails | CustomProductDetails = await this.client.get({
            url: `/selected-product/${ownership.type}/details/${productId}/${selectionType}`,
            params: {
                locale,
            },
        });

        if ((response.localizedDescriptions as any) instanceof Array) {
            response.localizedDescriptions = {};
        }

        if (response.details.hasOwnProperty('pharmacistPrice')) {
            this.guardResponseSchema('#/definitions/SelectedProductDetails', response);
        } else {
            this.guardResponseSchema('#/definitions/CustomProductDetails', response);
        }

        return response;
    }

    public async UpdateProductSelection(
        ownership: Ownership,
        selectionType: ProductSelectionType,
        productSelection: ProductSelection,
    ): Promise<void> {
        if (ownership.type !== 'branch') {
            throw new Error('Unsupported ownership type');
        }

        await this.client.post({
            url: '/command/dispatch/UpdateBranchExclusionRules',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\Branch\\UpdateBranchExclusionRules\\UpdateBranchExclusionRules',
                payload: {
                    branchId: ownership.ownerId,
                    selectionType,
                    exclusionRules: productSelection,
                },
            },
        });
    }

    public async CustomizeProductPricing(
        productId: string,
        ownership: Ownership,
        ownershipIds: {
            companyId?: string | undefined;
            branchId?: string | undefined;
        },
        selectionType: ProductSelectionType,
        priceIncludingVat?: number | null,
        vatRate?: number | null,
        promotionalPriceIncludingVat?: number | null,
    ): Promise<void> {
        let companyId;
        let branchId;

        if (ownership.type === 'company') {
            companyId = ownershipIds.companyId;
        } else if (ownership.type === 'branch') {
            companyId = ownershipIds.companyId;
            branchId = ownershipIds.branchId;
        } else {
            throw new Error('Unsupported ownership type');
        }

        await this.client.post({
            url: '/command/dispatch/CustomizeProductPricing',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Product\\ProductPrices\\CustomizeProductPrices\\CustomizeProductPrices',
                payload: {
                    availableProductId: productId,
                    selectionType,
                    companyId,
                    branchId,
                    priceIncludingVat: priceIncludingVat !== undefined ? priceIncludingVat : null,
                    vatRate: vatRate !== undefined ? vatRate : null,
                    promotionalPriceIncludingVat: promotionalPriceIncludingVat !== undefined ? promotionalPriceIncludingVat : null,
                },
            },
        });
    }

    public async BulkGetProductDetails(branchId: string, availableProductIds: string[]): Promise<BulkProductDetailsList> {
        try {
            const response: BulkProductDetailsList = await this.client.get({
                url: `/product/details/${branchId}/${availableProductIds.join(',')}`,
            });

            for (const item of response) {
                if ((item.localizedDescriptions as any) instanceof Array) {
                    item.localizedDescriptions = {};
                }
            }

            this.guardResponseSchema('#/definitions/BulkProductDetailsList', response);

            return response;
        } catch {
            return [];
        }
    }

    public async RemoveAllCustomPrices(branchId: string, companyId: string, selectionType: ProductSelectionType): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveAllCustomPrices',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Product\\ProductPrices\\RemoveAllCustomPrices\\RemoveAllCustomPrices',
                payload: {
                    sectionType: selectionType,
                    companyId,
                    branchId,
                },
            },
        });
    }
}
