import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopApi, scientificCategoryApi } from '@/api';
import * as categoryTreeState from '@/AvailableProduct/categoriesTreeState';
import { getCountryCodeFromLocale } from '@/getCountryCodeFromLocale';
import { URLParams } from '@/routing';
import { RootState } from '@/store';
import { selectGlobalSelectedWebshopId, selectGlobalSelectedWebshop } from '@/Webshop/globalSelectionState';

export interface State {
    configuration?: Domain.WebshopConfiguration;
    scientificCategoryTrees?: Domain.ScientificCategory;
}

const initialState: State = {};

const reducerActions = {
    setConfiguration: createAction('@webshopCartRestriction/setConfiguration', withPayloadType<Domain.WebshopConfiguration>()),
    setScientificCategoryTrees: createAction(
        '@webshopCartRestriction/setScientificCategoryTrees',
        withPayloadType<Domain.ScientificCategory>(),
    ),
};

export const updateReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setConfiguration, (state, action) => {
            state.configuration = action.payload;
        })
        .addCase(reducerActions.setScientificCategoryTrees, (state, action) => {
            state.scientificCategoryTrees = action.payload;
        }),
);

export const selectScientificCategoryTrees: Selector<RootState, Domain.ScientificCategory | undefined> = state =>
    state.webshopCartRestriction.update.scientificCategoryTrees;

export const loadScientificCategoryTrees = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const webshop = selectGlobalSelectedWebshop(state);
    if (!webshop) {
        return;
    }

    const country = getCountryCodeFromLocale(webshop.defaultLocale || webshop.locales[0]);
    const scientificCategoriesSources = state.localisation.availableCountries.countries[country]
        ? state.localisation.availableCountries.countries[country].scientificCategories
        : undefined;
    if (scientificCategoriesSources) {
        const categories = await Promise.all(
            scientificCategoriesSources.map(source => {
                return scientificCategoryApi.GetScientificCategoriesTree(source);
            }),
        );
        const concatenatedCategories = categories.reduce((acc, val) => ({
            ...acc,
            subCategories: acc.subCategories.concat(val.subCategories),
        }));
        await dispatch(reducerActions.setScientificCategoryTrees(concatenatedCategories));
    }
};
export const selectConfiguration: Selector<RootState, Domain.WebshopConfiguration> = state => {
    const configuration = state.webshopCartRestriction.update.configuration;
    if (!configuration) {
        throw new Error('Webshop configuration not loaded');
    }

    return configuration;
};

export const loadConfiguration = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const webshopId = selectGlobalSelectedWebshopId(state);
    if (!webshopId) {
        return;
    }
    const configuration = await webshopApi.GetWebshopConfiguration(webshopId);
    await dispatch(reducerActions.setConfiguration(configuration));
};

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const webshop = selectGlobalSelectedWebshop(state);
        if (!webshop) {
            return;
        }

        await Promise.all([
            dispatch(loadConfiguration()),
            dispatch(loadScientificCategoryTrees()),
            dispatch(
                categoryTreeState.actions.load({
                    ...options,
                    urlParams: {
                        locale: webshop.defaultLocale || webshop.locales[0],
                        ...options.urlParams,
                    },
                }),
            ),
        ]);
    };
