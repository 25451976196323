import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { ordersApi } from '@/api';
import { loadConfiguredIntegrationsCountByType } from '@/Integration/configuredIntegrationsTypeState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';

type WebshopOrderProps = keyof Domain.WebshopOrder | 'checkbox' | 'isExportedToIntegration';

export const defaultSorting: Domain.Sorting<WebshopOrderProps> = {
    field: 'createdAt',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.WebshopOrder, WebshopOrderProps>({
    getState: rootState => rootState.webshopOrder.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopOrder, WebshopOrderProps>({
    reducerPrefix: '@webshopOrder/overview',
});

export const baseActions = makeActions<Domain.WebshopOrder, WebshopOrderProps>({
    dataTableSaveKey: 'webshopOrdersOverview-v7',
    loadApi: options => {
        return ordersApi.GetOrders(
            options.pagination,
            options.sorting,
            {
                ...(options.filters ? options.filters : {}),
                salesChannelId: options.filters.webshopId ? options.filters.webshopId : options.globallySelectedWebshopId,
                salesChannelType: 'webshop',
            },
            options.search,
        ) as Promise<Domain.WebshopOrdersPage>;
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/orders',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<
    Domain.WebshopOrder,
    WebshopOrderProps,
    {
        paymentMethod?: string;
        shippingMethod?: string;
        orderStatus?: string;
        webshopId?: string;
    }
>({
    defaultSorting,
    pageSize,
    reducerActions,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            await dispatch(baseActions.load(options));
            await dispatch(loadConfiguredIntegrationsCountByType());
        },
};
