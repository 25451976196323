import * as React from 'react';

import { Route, isSignedIn, Import, CustomersImport } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/branch/customers/customers-import',
        main: () => import('./CustomersImportOverview'),
        isAuthorized: isSignedIn,
        preloadData: CustomersImport.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/branch/customers/customers-import/:importId',
        main: () => import('./CustomerImportDetail'),
        isAuthorized: isSignedIn,
        preloadData: Import.detailsState.actions.load,
        preloading: () => <FullPage bodyType="tabs-and-boxes" />,
    },
    {
        path: '/branch/customers/customers-import/new',
        main: () => import('./ImportPreview'),
        isAuthorized: isSignedIn,
        preloading: () => (
            <FullPage
                hideMainActions={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
