import { useEffect, useState } from 'react';

export default function useMediaQuery(query: string) {
    const [matches, setMatches] = useState<boolean>(matchMedia(query).matches);

    function onMediaQueryChangeEvent(event: MediaQueryListEvent) {
        setMatches(event.matches);
    }

    useEffect(() => {
        const media = matchMedia(query);
        media.addEventListener('change', onMediaQueryChangeEvent);

        return () => {
            media.removeEventListener('change', onMediaQueryChangeEvent);
        };
    }, []);

    return matches;
}
