import * as React from 'react';

import styles from './Flex.scss';

type TagName = keyof JSX.IntrinsicElements;

type TagProps<T extends TagName> = {
    as?: T | TagName;
} & (React.ComponentPropsWithoutRef<T> & React.HTMLAttributes<HTMLOrSVGElement>);

type FlexProps<T extends TagName> = TagProps<T> & {
    direction?: React.CSSProperties['flexDirection'];
    justifyContent?: React.CSSProperties['justifyContent'];
    alignItems?: React.CSSProperties['alignItems'];
    alignContent?: React.CSSProperties['alignContent'];
    wrap?: React.CSSProperties['flexWrap'] | boolean;
    gap?: React.CSSProperties['gap'];
    fullHeight?: boolean;
};

export default function Flex<T extends TagName>({
    as,
    children,
    className,
    direction,
    justifyContent,
    alignItems,
    wrap,
    gap,
    fullHeight,
    ...rest
}: FlexProps<T>) {
    const Component = as || 'div';
    return (
        <Component
            {...rest}
            className={`${styles.Flex} ${className || ''}`}
            style={{
                display: 'flex',
                height: fullHeight ? '100%' : 'auto',
                minHeight: fullHeight ? '0' : 'none',
                flexDirection: direction,
                justifyContent,
                alignItems,
                flexWrap: wrap ? (typeof wrap === 'string' ? wrap : 'wrap') : undefined,
                gap,
                ...rest.style,
            }}
        >
            {children}
        </Component>
    );
}

type FlexItemProps<T extends TagName> = TagProps<T> & {
    align?: React.CSSProperties['alignSelf'];
    spaceTop?: boolean | React.CSSProperties['marginTop'];
    spaceRight?: boolean | React.CSSProperties['marginRight'];
    spaceBottom?: boolean | React.CSSProperties['marginBottom'];
    spaceLeft?: boolean | React.CSSProperties['marginLeft'];
};

function spaceToMargin(space?: boolean | React.CSSProperties['marginTop']) {
    return space ? (typeof space === 'boolean' ? 'auto' : space) : 'none';
}

export function FlexItem<T extends TagName>({
    as,
    children,
    align,
    spaceTop,
    spaceRight,
    spaceBottom,
    spaceLeft,
    ...rest
}: FlexItemProps<T>) {
    const Component = as || 'div';
    return (
        <Component
            {...rest}
            style={{
                alignSelf: align,
                marginTop: spaceToMargin(spaceTop),
                marginRight: spaceToMargin(spaceRight),
                marginBottom: spaceToMargin(spaceBottom),
                marginLeft: spaceToMargin(spaceLeft),
                ...rest.style,
            }}
        >
            {children}
        </Component>
    );
}
