import { PRODUCT_CONSERVATION } from './';

export type CartRestrictionType =
    | 'product'
    | 'category'
    | 'brand'
    | 'organisation'
    | 'scientificCategory'
    | 'productWithConservationAdvice'
    | 'activeIngredient';

export interface CartRestriction {
    referenceId: string;
    limit: number;
}

export function getCartRestrictionType(referenceId: string): CartRestrictionType {
    if (referenceId.startsWith('product-')) {
        return 'product';
    } else if (referenceId.startsWith('category-')) {
        return 'category';
    } else if (referenceId.startsWith('brand-')) {
        return 'brand';
    } else if (referenceId.startsWith('organisation-')) {
        return 'organisation';
    } else if (referenceId.startsWith('scientificCategory-')) {
        return 'scientificCategory';
    } else if (
        referenceId.startsWith('productWithConservationAdvice-') ||
        PRODUCT_CONSERVATION.find(conservation => referenceId === conservation)
    ) {
        return 'productWithConservationAdvice';
    } else if (referenceId.startsWith('activeIngredient-')) {
        return 'activeIngredient';
    }

    throw new Error('Unexpected cart restriction referenceId: ' + referenceId);
}
