import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, WebshopCustomer } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/customers',
        main: () => import('./WebshopCustomersOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopCustomer.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/customers/:customerId',
        main: () => import('./ViewCustomer'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopCustomer.detailsState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/customers/edit/:customerId',
        main: () => import('./UpdateWebshopCustomer'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopCustomer.detailsState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/customers/:customerId/:tab',
        main: () => import('./ViewCustomer'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopCustomer.detailsState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
];

export default routes;
