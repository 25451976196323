import { Locale } from '@/Domain';
import { JsonWebToken, IdentifiedCustomerClaims } from '@/Infrastructure/JWT';

import Endpoint from './Endpoint';

interface AuthenticationResponse {
    accessToken: string;
    refreshToken: string;
}

interface DeviceAuthenticationResponse {
    token: string;
}

interface ImpersonateResponse {
    token: string;
}

export default class AuthenticationEndpoint extends Endpoint {
    public async GetToken(body: { emailAddress: string; password: string }): Promise<JsonWebToken> {
        const response = (await this.client.post({
            url: '/get-token',
            body,
        })) as AuthenticationResponse;

        return new JsonWebToken(response.accessToken, response.refreshToken);
    }

    public async GetDeviceToken(
        body:
            | {
                  deviceId: string;
              }
            | {
                  macAddress: string;
              },
    ): Promise<JsonWebToken> {
        const response = (await this.client.post({
            url: '/device/get-token',
            body,
            bodyIsFormData: true,
        })) as DeviceAuthenticationResponse;

        return new JsonWebToken(response.token, '');
    }

    public async GetDeviceSignalingTunnelToken(deviceId: string): Promise<JsonWebToken> {
        const response = await this.client.get({
            url: '/device/get-signaling-tunnel-token/' + deviceId,
        });

        return new JsonWebToken(response.token, '');
    }

    public async ParseToken(token: string): Promise<JsonWebToken> {
        return new JsonWebToken(token, '');
    }

    public async Impersonate(userId: string): Promise<JsonWebToken> {
        const response = (await this.client.get({
            url: '/impersonate/' + userId,
        })) as ImpersonateResponse;

        return new JsonWebToken(response.token, '');
    }

    public async ImpersonateByBranchId(branchId: string): Promise<JsonWebToken> {
        const response = (await this.client.get({
            url: '/impersonate/branchManager/' + branchId,
        })) as ImpersonateResponse;
        return new JsonWebToken(response.token, '');
    }
    public async RefreshToken(refreshToken: string): Promise<JsonWebToken> {
        const response = (await this.client.post({
            url: '/refresh-token',
            body: refreshToken,
            bodyIsString: true,
        })) as AuthenticationResponse;

        return new JsonWebToken(response.accessToken, response.refreshToken);
    }

    public async RequestNewPassword(body: { emailAddress: string; locale?: Locale }): Promise<void> {
        await this.client.post({
            url: '/user/request-password-reset',
            body,
            bodyIsFormData: true,
        });
    }

    public async SetNewPassword(body: { passwordResetRequestId: string; password: string }): Promise<void> {
        await this.client.post({
            url: '/user/reset-password',
            body,
            bodyIsFormData: true,
        });
    }

    public async GetIdentifiedVendingMachineCustomerToken(body: string): Promise<{
        value: string;
        claims: IdentifiedCustomerClaims;
    }> {
        return JsonWebToken.decodeIdentifiedCustomerToken(
            (await this.client.post({
                url: '/identified-customer/identify',
                body,
                bodyIsString: true,
            })) as string,
        );
    }
}
