import { LocalConfig } from './types';


const localConfig: LocalConfig = {
    apiBaseUrl: 'https://platform-api.dev.lochting.com',
    localCommWebSocketBaseUrl: 'ws://localhost:1337',
    lochtingDeviceBackendBaseUrl: 'http://localhost:8337',
    featureFlags: {
        disableDevDeviceSignalingAndMonitoring: false,
        enableBeamer: true,
        enableBacksideStocking: true,
        enableProductWallStockDisplayForBranches: ['branch-baldwin', 'branch-meditech'],
        enableNightHatchV2: true,
    },
    vendingMachinePrivacyPolicyPageUrl: 'https://platform.dev.lochting.com/vending-machine-privacy-policy',

}

export default localConfig
