import { Config } from './types';

const ALL_MEDITECH_COMPANY_IDS = [
    'company-9ece9e6b-836c-48a5-809b-fcef9e83c387',
    'company-428e293d-f34f-4bf9-bd61-d296fd21dcbc',
    'company-meditech',
    'company-eebe4fad-6f0b-4d23-923d-75ad7f5661cb',
    'company-b387c9ec-e94d-4381-b4cb-b87c9d9405f8',
    'company-f4b016b9-0f9c-4a9a-aa69-1d81235856f8',
    'company-be2645ee-5644-4722-9075-74f20604614c',
    'company-cc857b9b-c73d-4f00-b9f8-2f8cb8fe7ce0',
    'company-6411e8a5-e070-483e-814b-daa8897b991b',
];

const LOCAL_DEV_COMPANY_IDS = ['company-bf6f80b2-dd9b-4f34-83f1-e9291a87ac42'];

const defaultConfig: Config = {
    apiBaseUrl: 'https://platform-api.dev.lochting.com',
    deviceSignalingServerHost: 'signaling.dev.lochting.com',
    defaultLocale: 'en_BE',
    pickupCodePrefix: 'LRC',
    goodieBagCodePrefix: 'GBG',
    localCommWebSocketBaseUrl: 'ws://127.0.0.1:1337',
    lochtingDeviceBackendBaseUrl: 'http://127.0.0.1:8337',
    logging: {
        enabled: false,
        dataDog: {
            clientToken: 'pub1234xyz',
            env: 'local',
        },
    },
    videoCalling: {
        sipHost: 'meditech.pbxc.nl',
        sipWebsocketUri: 'wss://meditech.pbxc.nl:8089/ws',
        handleThroughLocalcomm: false,
    },
    hatchPhotos: {
        fetchThroughLocalcomm: false,
    },
    featureFlags: {
        disableDevDeviceSignalingAndMonitoring: false,
        allowMaticPaymentSkipWhenAmountIsZero: false,
        showOrderReviewProductConservations: true,
        enableBeamer: true,
        enableBacksideStocking: true,
        enableProductWallStockDisplayForBranches: [
            'branch-baldwin',
            'branch-648adb8b-4dc7-420e-8304-007bcfae8a89', // Pharmabest Pharmacie Flandre Littoral
        ],
        enableNightHatchV2: true,
        enableNightHatchV2OnlyForCompanies: [
            ...ALL_MEDITECH_COMPANY_IDS,

            'company-baldwin',
            ...LOCAL_DEV_COMPANY_IDS,
            'company-e2e4aa02-960e-466f-b53c-c33f3eba5338', // Apotheek Melotte
            'company-edd5b553-775c-4e94-a4c2-62136ce4d2f2', // Apotheek Noorderlaan
            'company-6fe12370-ea51-4ad6-afa8-8541d718f1de', // Pharmacie de Clervaux
            'company-503497cf-7c3d-4b8b-b784-f1ddb37162dc', // Pharmacie Des Capucins
        ],
        enableIdentifiedCustomersForCompanies: [...ALL_MEDITECH_COMPANY_IDS, 'company-baldwin', ...LOCAL_DEV_COMPANY_IDS],
    },
    apiValidationThrowsException: true,
    useDebugConsole: true,
    productImagePlaceholder: '/static/products/no-image.svg',
    vendingMachinePrivacyPolicyPageUrl: 'https://platform.lochting.com/vending-machine-privacy-policy',
};

export default defaultConfig;
